@font: ProximaNova, 'Open Sans', 'Helvetica Neue', sans-serif;
:root {
  //TODO
  --black: #171725;
  --black-link-underline-color: #c5c5c8;
  --blue: #50b5ff;
  --blue-primary: #0062ff;
  --light-blue: #f2f7ff;
  --green: #3dd598;
  --grey-1: #44444f;
  --grey-2: #696974;
  --grey-3: #92929d;
  --grey-4: #b5b5be;
  --grey-5: #d5d5dc;
  --light-grey-1: #e2e2ea;
  --light-grey-2: #f1f1f5;
  --light-grey-3: #fafafb;
  --orange: #ff974a;
  --red: #fc5a5a;
  --white: #ffffff;
  --white-03: hsla(0, 0%, 100%, 0.3);
  --yellow: #ffc542;
  --color-gray-ultra-light: #f5f5f7;
  --color-gray-pale-light: #f6f9fb;
}
// @todo MYBASE
@color-light-black: #44566c;
@color-extra-light-blue: #deeefc;
@color-gray-new: #e8ebf2;
@color-green: #3bc7ab;
@color-green-opacity: #d8f4ef;
@color-dark-light-green: #2ecfae;
@color-ultra-light-green: #9de3d5;
@color-dark-gray: #556066;
@color-gray: #9aa5ab;
@color-gray-blue: #fafafb;
@color-default-gray: #c4c7cc;
@color-light-gray: #e4e5e9;
@color-super-light-gray: #f5f5f7;
@color-light-green: #beeae3;
@color-extra-light-green: #e2f7f2;
@color-light-yellow: #fffcf1;

/* NEW */

//@black: #171725;
//@black-link-underline-color: #c5c5c8;
//@blue: #50b5ff;
//@blue-primary: #0062ff;
//@light-blue: #f2f7ff;
//@green: #3dd598;
//@grey-1: #44444f;
//@grey-2: #696974;
//@grey-3: #92929d;
//@grey-4: #b5b5be;
//@grey-5: #d5d5dc;
//@light-grey-1: #e2e2ea;
//@light-grey-2: #f1f1f5;
//@light-grey-3: #fafafb;
//@orange: #ff974a;
//@red: #fc5a5a;
//@white: #ffffff;
//@yellow: #ffc542;

// new
@black: var(--black);
@black-link-underline-color: #c5c5c8;
@blue: #50b5ff;
@blue-primary: #0062ff;
@blue-primary-02: #cce0ff;
@light-blue: #f2f7ff;
@green: #3dd598;
@green-2: #3aca90;
@green-3: #37c089;
@green-4: #ecfbf5;
@grey-1: var(--grey-1);
@grey-2: #696974;
@grey-3: #92929d;
@grey-4: var(--grey-4);
@grey-5: #d5d5dc;
@light-grey-1: var(--light-grey-1);
@light-grey-2: var(--light-grey-2);
@light-grey-3: var(--light-grey-3);
@orange: #ff974a;
@red: #fc5a5a;
@white: var(--white);
@white-03: var(--white-03);
@yellow: #ffc542;
@Color-gray-ultra-light: var(--color-gray-ultra-light);
@Color-gray-pale-light: var(--color-gray-pale-light);
@always-white: #ffffff;
@light-red: #ffefef;
@light-green: #ecfbf5;

@big-shadow: -4px 5px 18px rgba(29, 42, 68, 0.13);
@small-shadow: -1px 1px 4px rgba(58, 70, 93, 0.12);
@blue-big-shadow: -4px 5px 18px rgba(0, 98, 255, 0.1);

/* END NEW */

@shadow-default: -1px 1px 4px 0 rgba(58, 70, 93, 0.12);
@border-radius-default: 8px;
@border-radius-secondary: 6px;
@border-radius-form: 4px;

/**/

// @todo MYBASE END

/*color*/
@Color-super-light-red: #fcefe9;
@Color-dark-gray: #556066;
@Color-super-light-gray: #f5f5f7;
@Color-ultra-light-blue: #d8f6fd;
@Color-dark-blue: #1f8ceb;
@Color-blue: #409ded;
@Color-orange_2: #e86d31;
@Color-gold: #f4c221;
@Color-orange: #fba66e;
@Color-light-green: #d7f2ed;
@Color-gray: #a5a5a5;
@Color-dark-light-gray: #9aa5ab;
@Color-gray-default: #c4c7cc;
@Color-gray-light: #e4e5e9;
//@Color-gray-pale-light: #f6f9fb;
// @Color-gray-ultra-light: #f5f5f7;
@Color-blue_2: #1f8ceb;
@Color-vk: #2787f5;
@Color-fb: #3b5998;
@Color-yellow_2: #f4c221;

/*border-radius*/
@Radius-default: 4px;
@Radius-six: 6px;
@Radius-twelve: 12px;
@Radius-full: 100%;
@Border-default: solid 1px @Color-gray-light;

/*queries*/
@phone: ~'all and (max-width: 600px)';
@tablet: ~'all and (max-width: 800px)';
@double-header-threshold: ~'all and (max-width: 1130px)';
@Media-max-1440: ~'all and (max-width: 1439px)';
@Media-max-1425: ~'all and (max-width: 1425px)';
@Media-max-1400: ~'all and (max-width: 1400px)';
@Media-max-1399: ~'all and (max-width: 1399px)';
@Media-max-1340: ~'all and (max-width: 1340px)';
@Media-max-1300: ~'all and (max-width: 1300px)';
@Media-max-1225: ~'all and (max-width: 1225px)';
@Media-max-1230: ~'all and (max-width: 1230px)';
@Media-max-1200: ~'all and (max-width: 1200px)';
@Media-max-1070: ~'all and (max-width: 1070px)';
@Media-max-1130: ~'all and (max-width: 1130px)';
@Media-max-970: ~'all and (max-width: 970px)';
@Media-max-900: ~'all and (max-width: 900px)';
@Media-max-850: ~'all and (max-width: 850px)';
@Media-max-780: ~'all and (max-width: 780px)';
@Media-max-759: ~'all and (max-width: 759px)';
@Media-max-650: ~'all and (max-width: 650px)';
@Media-max-550: ~'all and (max-width: 550px)';
@Media-max-500: ~'all and (max-width: 500px)';
@Media-max-height-650: ~'all and (max-height: 650px)';
@Media-max-height-580: ~'all and (max-height: 580px)';
@Media-max-height-500: ~'all and (max-height: 500px)';
@Media-max-455: ~'all and (max-width: 455px)';
@Media-max-415: ~'all and (max-width: 415px)';
@Media-max-400: ~'all and (max-width: 400px)';
@Media-max-375: ~'all and (max-width: 375px)';
@Media-min-550: ~'all and (min-width: 550px)';
@Media-min-971: ~'all and (min-width: 971px)';
@Media-min-1130: ~'all and (min-width: 1131px)';
@Media-min-1360: ~'all and (min-width: 1360px)';
@Media-min-1426: ~'all and (min-width: 1426px)';
@Media-min-1440: ~'all and (min-width: 1440px)';
@Media-min-1600: ~'all and (min-width: 1600px)';
@Media-min-1920: ~'all and (min-width: 1920px)';
@Media-min-2000: ~'all and (min-width: 2000px)';
@Media-min-2240: ~'all and (min-width: 2240px)';
@Media-min-2490: ~'all and (min-width: 2490px)';

@Media-mobile-app: ~'all and (max-width: 550px)';
