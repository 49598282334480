@keyframes blink {
  55% {
    opacity: 0;
  }
  95% {
    opacity: 1;
  }
  70% {
    opacity: 0.9;
  }
}

@keyframes showUp {
  0% {
    max-height: 0;
    opacity: 0;
  }
  50% {
    opacity: 0.9;
  }
  to {
    max-height: 1000px;
    opacity: 1;
  }
}
