@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import 'less/fonts';
@import 'less/variables';
@import 'less/matdialog';
@import 'less/mixins';
@import 'less/link';
@import 'less/btn';
@import 'less/onboarding';
@import 'less/circles';
@import 'less/dialog';
@import 'less/typography';
@import 'less/keyframes';
@import 'less/must-be-global';
@import 'less/tooltip';

// forms
@import 'less/forms/checkbox';
@import 'less/forms/contenteditable';
@import 'less/forms/green-checkbox';
@import 'less/forms/input';
@import 'less/forms/radio';
@import 'less/forms/textarea';
@import 'less/forms/label';
@import 'less/forms/field';
@import 'less/forms/form-group';
@import 'less/forms/error-text';
@import 'less/forms/label-group';
@import 'less/forms/input-with-btn';

@import '~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css';
@import 'less/forms/owldatepicket';

*:focus {
  outline: none;
}

[hidden] {
  display: none !important;
}

html,
body {
  min-height: 100%;
  height: 100%;
  width: 100%;
}

body {
  .body-text();
  .body-text_style_1();
}

body {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  padding: 0;
  margin: 0;
  font-family: @font;
  background: @light-grey-3;
  transition: background-color 50ms ease-out;
  -webkit-tap-highlight-color: transparent;
  &.rt-body-white {
    background: @white;
  }

  &.no-mac {
    ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    ::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #e1e1e1;
      border: 0 none #ffffff;
      border-radius: 50px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #b6b6b6;
    }
    ::-webkit-scrollbar-thumb:active {
      background: #b6b6b6;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
      border: 0 none transparent;
      border-radius: 14px;
    }
    ::-webkit-scrollbar-track:hover {
      background: transparent;
    }
    ::-webkit-scrollbar-track:active {
      background: transparent;
    }
    ::-webkit-scrollbar-corner {
      background: transparent;
    }
  }
}

button,
input {
  font-family: @font;
  font-size: inherit;
  font-weight: inherit;
}

.overflow {
  overflow: hidden;
}

a {
  cursor: pointer;
  text-decoration: none;
}

.logtime__hashtag,
.logtime__linky-link {
  word-wrap: break-word;
  color: @Color-blue;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

/* Animate items as they're being sorted. */
.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* Animate an item that has been dropped. */
.cdk-drag-animating {
  transition: transform 300ms;
}

.cdk-drag-animating.cdk-drag-animating-hide {
  opacity: 0;
}

/* Скрытие svg спрайта */
body > svg {
  display: none;
}

#chatra:not(.chatra--expanded) {
  visibility: hidden !important;
  opacity: 0 !important;
  pointer-events: none;
  transition: none;
}
