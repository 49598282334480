.link {
  &_not-link {
    color: inherit;

    &:hover,
    &:active,
    &:visited,
    &:link {
      color: inherit;
      text-decoration: none;
    }
  }

  &_black {
    display: inline-block;
    box-sizing: border-box;
    height: 14px;
    line-height: 14px;
    color: @black;
    border-bottom: 1px solid @black-link-underline-color;
  }

  &_dark-blue {
    color: @blue-primary;
  }

  &_white {
    border-bottom: 1px solid @white;
  }
}

.payment-select {
  &__offer-link {
    color: inherit;
    border-bottom: 1px solid;
  }
}
