@import '~src/less/mixins';
@import '~src/less/variables.less';
@import '~src/less/typography.less';

.input {
  .placeholder-color();
  background-color: @white;
  border: 1px solid @light-grey-1;
  border-radius: @border-radius-form;
  padding-left: 15px;
  padding-right: 15px;
  height: 40px;
  width: 100%;
  box-sizing: border-box;
  color: @black;
  .body-text_style_2();
  line-height: normal;
  &_icon-right {
    padding-right: 2.5rem;
  }
  &:hover {
    border-color: @grey-4;
  }
  &&_error {
    border-color: @red;
    background-color: fadeout(@red, 95%);
  }
  &&_ok {
    border-color: @green;
  }
  &:focus,
  &_active {
    border-color: @blue;
  }
  &:disabled {
    pointer-events: none;
    background-color: @light-grey-3;
    border-color: @light-grey-1;
    color: @grey-3;
  }

  &&_datepicker {
    padding-left: 3rem;
    position: relative;
    display: flex;
    align-items: center;
    .ui-icon {
      position: absolute;
      left: 13px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  display: none;
}
