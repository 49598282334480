.heading() {
  font-weight: bold;
  color: @black;
}

.heading_h1() {
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.5px;
}

.heading_h2() {
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.005em;
}

.heading_h3() {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.5px;
  font-weight: 700;
}

/* --- */

.heading {
  .heading();

  &_h1 {
    .heading_h1();
  }

  &_h2 {
    .heading_h2();
  }

  &_h3 {
    .heading_h3();
  }
}

.text() {
  font-size: 18px;
  line-height: 26px;
  color: @black;
  font-weight: normal;
}

/* --- */
.text {
  .text();
}

/* Адовые микины */
.body-text() {
  color: @black;
}

.color_grey-1 {
  color: @grey-1;
}

/* --- */
.body-text_style_1() {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.body-text_style_2() {
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
}

.body-text_style_3() {
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
}

.body-text {
  .body-text();

  &_style_1 {
    .body-text_style_1();
  }

  &_style_2 {
    .body-text_style_2();
  }

  &_style_3 {
    .body-text_style_3();
  }
}

.error() {
  .body-text_style_2();
  color: @red;
}

.caption() {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: @grey-3;
}

/* --- */
.caption {
  .caption();
}

.rt-color-green {
  color: @green;
}

.rt-color-white {
  color: @white;
}

.rt-color-yellow {
  color: @yellow;
}
