@import '~src/less/variables';

.cdk-overlay-pane {
  padding: 0;
  max-width: none;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.6);
}

.mat-dialog-container {
  background-color: transparent;
  box-shadow: none !important;
  @media @Media-max-650 {
    padding: 0 !important;
  }

  & > * {
    box-shadow: @big-shadow;
  }
}
