@import '~src/less/mixins.less';

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='file'] {
  display: none;
}

.btn {
  cursor: pointer;
  border-radius: @Radius-default;
  margin-top: 0;
  margin-bottom: 0;
  position: relative;

  &_not-button {
    .button-reset();
    cursor: pointer;
  }

  &_add {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    width: 100%;
    z-index: 31;
    border-radius: 0;
    background: @white;
    .body-text_style_1();
    color: @grey-3;
    &:hover {
      color: @grey-2;
    }
  }

  &_loading {
    &:after {
      background-image: url(../assets/loaders/st1.svg);
      background-repeat: no-repeat;
      background-position: center;
      content: '';
      position: absolute;
      left: 5px;
      right: 5px;
      bottom: 7px;
      top: 7px;
      background-color: #fff;
    }
  }
}

.btn-icon {
  color: @grey-4;
  padding: 8px;
  border-radius: 8px;

  &:hover,
  &:active,
  &_active {
    background: @light-grey-3;
    color: @grey-2;
  }
  &_small {
    padding: 4px;
    border-radius: 6px;
  }
}

.mat-ripple-element {
  background-color: currentColor;
  opacity: 0.1;
}
