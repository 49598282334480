@import 'variables';

.transition-color() {
  transition: color 0.2s ease;
}

.transition-margin-opacity() {
  transition: margin 0.2s ease, opacity 0.05s ease 0.1s;
}

.transition-shadow() {
  transition: box-shadow 0.2s ease;
}

.transition-all() {
  transition: all 0.2s ease;
}

.transition-opacity() {
  transition: opacity 0.2s ease;
}

.transition-border-color() {
  transition: border-color 0.2s ease;
}

.transition-bg-color() {
  transition: background-color 0.2s ease;
}

.input-prop() {
  color: @Color-dark-gray;
  background-color: @white;
  border: solid 1px @Color-gray-light;
  border-radius: @Radius-default;
  box-sizing: border-box;
  &:hover {
    border-color: @Color-gray-default;
  }
  &:focus {
    border-color: @Color-blue;
  }
  &_active {
    border-color: @Color-blue !important;
  }
  .transition-border-color();
}

.popup-prop() {
  border-radius: @border-radius-default;
  background-color: @white;
  box-shadow: @big-shadow;
}

.close-position() {
  position: absolute !important;
  z-index: 2;
  padding: 16px;
}

.container-prop() {
  .shadow-small();
  background-color: @white;
  border-radius: @border-radius-default;
}

.shadow-small() {
  box-shadow: -1px 1px 4px rgba(58, 70, 93, 0.12);
}

.shadow-big() {
  box-shadow: -4px 5px 18px rgba(29, 42, 68, 0.13);
}

.shadow-top-bar() {
  box-shadow: 2px -1px 20px rgba(29, 42, 68, 0.06);
}

.blink-animation() {
  animation: 1.4s ease-in-out infinite blink;
}

.button-reset() {
  border: none;
  background: transparent;
  padding: 0;
  text-align: left;
}

.placeholder-color() {
  &::placeholder {
    color: @grey-3;
  }
}

.margins-top-bottom {
  margin-top: 72px;
}

.divider() {
  border-bottom: 1px solid @light-grey-2;
  height: 1px;
}
