.owl-dt-calendar-table {
  .owl-dt-calendar-cell {
    color: @black;
  }
}

.owl-dt-control-content {
  color: @black;
}

.owl-dt-calendar-control {
  padding: 1em 0.5em 1em 0.5em;
}

.owl-dt-control-arrow-button {
  width: 32px;
  height: 32px;
  background: @light-grey-3;
  border-radius: @border-radius-form;
  .owl-dt-control-button-content {
    color: @grey-1;
  }
  &:disabled {
    .owl-dt-control-button-content {
      color: @grey-4;
    }
  }
}

.owl-dt-calendar-table
  .owl-dt-calendar-cell-disabled
  > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
  color: @grey-4;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-out {
  opacity: 1;
  color: @grey-4;
}

.owl-dt-popup-container {
  border-radius: @border-radius-default;
  box-shadow: @big-shadow;
}

.owl-dt-calendar-table-divider {
  display: none;
}

.owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-weekdays th {
  .body-text_style_2();
  color: @grey-3;
}

.owl-dt-calendar-table .owl-dt-calendar-header {
  color: @grey-4;
}

.owl-dt-calendar-table
  .owl-dt-calendar-cell-selected.owl-dt-calendar-cell-today {
  box-shadow: none;
}

.owl-dt-calendar-table
  .owl-dt-calendar-cell-active:focus
  > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected),
.owl-dt-calendar-table
  :not(.owl-dt-calendar-cell-disabled):hover
  > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
  background: transparent;
}

.owl-dt-calendar-table
  .owl-dt-calendar-cell-active:focus:not(:hover)
  > .owl-dt-calendar-cell-content.owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected),
.owl-dt-calendar-cell-content .owl-dt-calendar-cell-today,
.owl-dt-calendar-table
  .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
  border: none;
  background: @red;
  color: @white;
}

.owl-dt-calendar-table
  :not(.owl-dt-calendar-cell-disabled):hover
  > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
  // background-color: @light-grey-2;
  border: 1px solid @blue-primary;
  background: rgba(@blue-primary, 0.05);
  border-radius: 4px;
  color: @black;
}

.owl-dt-control-period-button:hover > .owl-dt-control-button-content {
  background-color: @light-grey-2;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
  background-color: @blue-primary;
  border-radius: 4px;
  color: @white;
  border: none;
}

.owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-weekdays th {
  padding-bottom: 0;
  text-transform: uppercase;
}

.owl-dt-inline-container .owl-dt-calendar,
.owl-dt-popup-container .owl-dt-calendar {
  height: auto;
}
