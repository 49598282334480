@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css';
@font-face {
  font-family: 'ProximaNova';
  src: url(./assets/fonts/ProximaNova-Bold.otf) format('opentype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'ProximaNova';
  src: url(./assets/fonts/ProximaNova-BoldIt.otf) format('opentype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'ProximaNova';
  src: url(./assets/fonts/ProximaNova-Light.otf) format('opentype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'ProximaNova';
  src: url(./assets/fonts/ProximaNova-LightIt.otf) format('opentype');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'ProximaNova';
  src: url(./assets/fonts/ProximaNova-Semibold.otf) format('opentype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'ProximaNova';
  src: url(./assets/fonts/ProximaNova-Reg.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ProximaNova';
  src: url(./assets/fonts/ProximaNova-RegIt.otf) format('opentype');
  font-weight: normal;
  font-style: italic;
}
:root {
  --black: #171725;
  --black-link-underline-color: #c5c5c8;
  --blue: #50b5ff;
  --blue-primary: #0062ff;
  --light-blue: #f2f7ff;
  --green: #3dd598;
  --grey-1: #44444f;
  --grey-2: #696974;
  --grey-3: #92929d;
  --grey-4: #b5b5be;
  --grey-5: #d5d5dc;
  --light-grey-1: #e2e2ea;
  --light-grey-2: #f1f1f5;
  --light-grey-3: #fafafb;
  --orange: #ff974a;
  --red: #fc5a5a;
  --white: #ffffff;
  --white-03: hsla(0, 0%, 100%, 0.3);
  --yellow: #ffc542;
  --color-gray-ultra-light: #f5f5f7;
  --color-gray-pale-light: #f6f9fb;
}
/* NEW */
/* END NEW */
/**/
/*color*/
/*border-radius*/
/*queries*/
@media (max-width: 650px) {
  .matdialog_adaptive {
    width: 100vw;
    border-radius: 8px 8px 0 0;
  }
}
.matdialog__panel_global-scroll {
  position: fixed !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.matdialog__panel_global-scroll .mat-dialog-container {
  width: 100%;
  max-width: 100%;
  height: 100vh;
  overflow: auto;
  box-shadow: none;
  display: flex;
}
.matdialog__panel_global-scroll .mat-dialog-container > * {
  display: block;
  margin: auto;
  background: var(--white);
  border-radius: 8px;
}
.black-backdrop {
  background: rgba(0, 0, 0, 0.7);
}
.margins-top-bottom {
  margin-top: 72px;
}
.link_not-link {
  color: inherit;
}
.link_not-link:hover,
.link_not-link:active,
.link_not-link:visited,
.link_not-link:link {
  color: inherit;
  text-decoration: none;
}
.link_black {
  display: inline-block;
  box-sizing: border-box;
  height: 14px;
  line-height: 14px;
  color: var(--black);
  border-bottom: 1px solid #c5c5c8;
}
.link_dark-blue {
  color: #0062ff;
}
.link_white {
  border-bottom: 1px solid var(--white);
}
.payment-select__offer-link {
  color: inherit;
  border-bottom: 1px solid;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}
input[type='number'] {
  -moz-appearance: textfield;
}
input[type='file'] {
  display: none;
}
.btn {
  cursor: pointer;
  border-radius: 4px;
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
}
.btn_not-button {
  border: none;
  background: transparent;
  padding: 0;
  text-align: left;
  cursor: pointer;
}
.btn_add {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  width: 100%;
  z-index: 31;
  border-radius: 0;
  background: var(--white);
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #92929d;
}
.btn_add:hover {
  color: #696974;
}
.btn_loading:after {
  background-image: url(./assets/loaders/st1.svg);
  background-repeat: no-repeat;
  background-position: center;
  content: '';
  position: absolute;
  left: 5px;
  right: 5px;
  bottom: 7px;
  top: 7px;
  background-color: #fff;
}
.btn-icon {
  color: var(--grey-4);
  padding: 8px;
  border-radius: 8px;
}
.btn-icon:hover,
.btn-icon:active,
.btn-icon_active {
  background: var(--light-grey-3);
  color: #696974;
}
.btn-icon_small {
  padding: 4px;
  border-radius: 6px;
}
.mat-ripple-element {
  background-color: currentColor;
  opacity: 0.1;
}
.onboarding {
  box-sizing: border-box;
  position: absolute;
  visibility: visible;
  padding: 20px;
  background-color: #409ded;
  color: var(--white);
  width: 302px;
  border-radius: 3px;
  box-shadow: -1px 1px 4px rgba(58, 70, 93, 0.12);
  -webkit-transition: opacity 0.1s ease-out;
  -moz-transition: opacity 0.1s ease-out;
  -ms-transition: opacity 0.1s ease-out;
  -o-transition: opacity 0.1s ease-out;
  transition: opacity 0.1s ease-out;
}
.onboarding__list {
  padding-left: 15px;
  margin-bottom: 18px;
}
.onboarding_left {
  right: 100%;
  top: -25px;
  margin-right: 50px;
}
.onboarding__btn {
  margin-top: 16px;
  text-align: right;
  padding: 11px 0;
}
.onboarding__title {
  margin: 0 0 16px;
}
.onboarding__text {
  margin: 0 0 16px;
  display: block;
}
.onboarding__text_with-devider {
  padding-left: 20px;
}
.onboarding__item {
  padding-left: 3px;
  margin-bottom: 0;
  position: relative;
  z-index: 1;
}
.onboarding__item_numb {
  display: list-item;
}
.onboarding__item_dev:before {
  content: '\2014';
  position: absolute;
  left: -17px;
  top: 0;
}
.onboarding__devider {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.onboarding__arrow {
  border: 15px solid transparent;
  content: '';
  position: absolute;
}
.onboarding__arrow_top {
  top: -30px;
  border-bottom-color: #409ded;
}
.onboarding__arrow_top-right {
  top: -30px;
  right: -30px;
  border-bottom-color: #409ded;
}
.onboarding__arrow_top-middle {
  top: -30px;
  left: 50%;
  margin-left: -5px;
  border-bottom-color: #409ded;
}
.onboarding__arrow_right {
  right: -30px;
  top: 25px;
  border-left-color: #409ded;
}
.onboarding__arrow_right-bottom {
  bottom: 10px;
  right: -30px;
  border-left-color: #409ded;
}
.onboarding__arrow_bottom {
  bottom: -30px;
  border-top-color: #409ded;
}
.onboarding__arrow_bottom-right {
  bottom: -10px;
  right: 10px;
  border-top-color: #409ded;
}
.onboarding__arrow_bottom-middle {
  bottom: -10px;
  left: 50%;
  margin-left: -5px;
  border-top-color: #409ded;
}
.onboarding__arrow_left,
.onboarding__arrow_left-bottom {
  left: -30px;
  border-right-color: #409ded;
}
.onboarding__arrow-left {
  top: 40px;
}
.onboarding__arrow_left-bottom {
  bottom: 23px;
}
.onboarding__img {
  display: grid;
  justify-content: center;
  margin-bottom: 16px;
}
.onboarding__icon-rocket {
  width: 124px;
  height: 124px;
  color: var(--white);
}
.onboarding .btn {
  margin-left: 16px;
}
.new-onboarding-dialog {
  position: relative;
  display: block;
  box-sizing: border-box;
  width: 407px;
  padding: 48px;
  border-radius: 8px;
  text-align: center;
  background: var(--white);
  color: var(--grey-1);
}
@media all and (max-width: 650px) {
  .new-onboarding-dialog {
    width: 100vw;
    padding: 32px;
  }
}
.new-onboarding-dialog__button {
  display: inline-block;
  box-sizing: border-box;
  width: 344px;
  max-width: 100%;
  padding: 12px;
  border-radius: 8px;
  text-align: center;
  color: var(--white);
}
.new-onboarding-dialog__close {
  position: absolute !important;
  z-index: 1;
  top: 24px;
  right: 24px;
}
.new-onboarding-dialog__img {
  margin-right: 16px;
}
.new-onboarding-dialog__item {
  margin-bottom: 16px;
  padding-left: 3px;
}
.new-onboarding-dialog__item_numberless {
  list-style: none;
}
.new-onboarding-dialog__header {
  display: flex;
  align-items: center;
  margin: 0 0 24px;
}
.new-onboarding-dialog__green {
  color: #3dd598;
}
.new-onboarding-dialog__list {
  box-sizing: border-box;
  margin-bottom: 32px;
  padding-left: 18px;
  text-align: left;
}
.new-onboarding-dialog__step-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: rgba(61, 213, 152, 0.3);
  font-size: 28px;
  line-height: 36px;
  color: #3dd598;
  font-weight: bold;
}
.new-onboarding-dialog__title {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.5px;
  font-weight: 700;
  margin: 0;
  color: var(--black);
}
.new-onboarding-dialog__title_h1 {
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.5px;
}
.new-onboarding-dialog__title_h2 {
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.005em;
}
.new-onboarding-dialog__title_h3 {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.5px;
  font-weight: 700;
}
.circle {
  width: 8px;
  height: 8px;
  flex-shrink: 0;
  display: inline-block;
  border-radius: 100%;
}
.circle_select {
  margin-right: 12px;
}
.cdk-overlay-pane {
  padding: 0;
  max-width: none;
}
.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.6);
}
.mat-dialog-container {
  background-color: transparent;
  box-shadow: none !important;
}
@media all and (max-width: 650px) {
  .mat-dialog-container {
    padding: 0 !important;
  }
}
.mat-dialog-container > * {
  box-shadow: -4px 5px 18px rgba(29, 42, 68, 0.13);
}
/* --- */
.heading {
  font-weight: bold;
  color: var(--black);
}
.heading_h1 {
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.5px;
}
.heading_h2 {
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.005em;
}
.heading_h3 {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.5px;
  font-weight: 700;
}
/* --- */
.text {
  font-size: 18px;
  line-height: 26px;
  color: var(--black);
  font-weight: normal;
}
/* Адовые микины */
.color_grey-1 {
  color: var(--grey-1);
}
/* --- */
.body-text {
  color: var(--black);
}
.body-text_style_1 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
.body-text_style_2 {
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
}
.body-text_style_3 {
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
}
/* --- */
.caption {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #92929d;
}
.rt-color-green {
  color: #3dd598;
}
.rt-color-white {
  color: var(--white);
}
.rt-color-yellow {
  color: #ffc542;
}
@keyframes blink {
  55% {
    opacity: 0;
  }
  95% {
    opacity: 1;
  }
  70% {
    opacity: 0.9;
  }
}
@keyframes showUp {
  0% {
    max-height: 0;
    opacity: 0;
  }
  50% {
    opacity: 0.9;
  }
  to {
    max-height: 1000px;
    opacity: 1;
  }
}
/* Drag and Drop */
.note-form__subtask-text {
  flex-basis: 100%;
  box-sizing: border-box;
  margin: 0 0 0 -6px;
  min-height: 32px;
  padding: 7px 0 0 6px;
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  color: var(--black);
  font-family: ProximaNova, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  resize: none;
  transition: background-color 0.2s ease;
}
.note-form__subtask-text:hover,
.note-form__subtask-text:focus {
  background-color: rgba(0, 98, 255, 0.05);
}
.note-form__subtask.cdk-drag-preview .note-form__subtask-delete {
  display: none;
}
.bold {
  font-weight: bold;
  color: var(--black);
}
.custom-preview {
  height: auto !important;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: -4px 5px 18px rgba(29, 42, 68, 0.13);
  background: var(--white);
  transition: 0.2s;
  width: 98%;
}
.custom-preview-opacity {
  height: auto !important;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: -4px 5px 18px rgba(29, 42, 68, 0.13);
  background: var(--white);
  transition: 0.2s;
  width: 98%;
  opacity: 0.5;
}
.custom-preview-animating {
  opacity: 0;
}
/* End drag and drop */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.reminder-container {
  border-radius: 8px;
  background-color: var(--white);
  box-shadow: -4px 5px 18px rgba(29, 42, 68, 0.13);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.reminder-menu-item {
  border: none;
  background: transparent;
  padding: 0;
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  transition: background-color 0.2s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 8px;
  border-radius: 8px;
  color: #696974;
}
.reminder-menu-item:hover {
  background: var(--light-grey-3);
}
.reminder-menu-item_active {
  color: #0062ff;
}
.reminder-menu-item_active .reminder-menu-item__arrow {
  color: #0062ff;
}
.reminder-menu-item_active .reminder-menu-item__date {
  color: #0062ff;
}
.reminder-menu-item_active .reminder-menu-item__icon-done {
  opacity: 1;
}
.reminder-menu-item__arrow {
  color: var(--grey-4);
}
.reminder-menu-item__light {
  opacity: 0.45;
}
.reminder-menu-item__icon-done {
  opacity: 0;
  color: #0062ff;
}
.reminder-menu-item__group {
  display: flex;
  align-items: center;
}
.reminder-menu-item__group_main {
  color: var(--black);
}
.reminder-menu-item__main-icon {
  margin-right: 12px;
}
.reminder-menu-item__icon-delete {
  color: #92929d;
  margin-right: 4px;
}
.reminder-menu-item__date {
  margin-right: 8px;
  color: #92929d;
}
.reminder-menu-item__delete {
  color: #92929d;
}
.reminder-table-item {
  user-select: none;
  box-sizing: border-box;
}
.reminder-table-item input + label {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  transition: all 0.2s ease;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  padding: 6px;
  border-radius: 4px;
  color: var(--grey-1);
  box-sizing: border-box;
}
.reminder-table-item input + label:hover {
  background: var(--light-grey-2);
}
.reminder-table-item input + label:active {
  background: var(--light-grey-3);
}
.reminder-table-item input:checked + label {
  background: #0062ff;
  color: var(--white);
}
.reminder-table-item_last input + label {
  white-space: nowrap;
  width: fit-content;
  justify-content: flex-start;
}
.tooltip__arrow,
.tooltip__arrow:before {
  position: absolute;
  width: 8px;
  height: 8px;
  z-index: -1;
}
.tooltip__arrow:before {
  content: '';
  transform: rotate(45deg);
  background: #0062ff;
}
.tooltip__arrow_big,
.tooltip__arrow_big:before {
  width: 24px;
  height: 24px;
}
.tooltip__arrow_hidden {
  display: none;
}
.tooltip_no-space {
  display: flex;
}
.tooltip__tip {
  background: #0062ff;
  color: var(--white);
  border-radius: 8px;
  box-shadow: unset;
  padding: 9px 16px 8px;
  text-align: unset;
  width: unset;
  animation-name: showUp;
  animation-duration: 0.4s;
}
.tooltip__tip[data-popper-placement^='top'] > .tooltip__arrow {
  bottom: -4px;
}
.tooltip__tip[data-popper-placement^='top'] > .tooltip__arrow_big {
  bottom: -9px;
}
.tooltip__tip[data-popper-placement^='bottom'] > .tooltip__arrow {
  top: -4px;
}
.tooltip__tip[data-popper-placement^='bottom'] > .tooltip__arrow_big {
  top: -9px;
}
.tooltip__tip[data-popper-placement^='left'] > .tooltip__arrow {
  right: -4px;
}
.tooltip__tip[data-popper-placement^='left'] > .tooltip__arrow_big {
  right: -9px;
}
.tooltip__tip[data-popper-placement^='right'] > .tooltip__arrow {
  left: -4px;
}
.tooltip__tip[data-popper-placement^='right'] > .tooltip__arrow_big {
  left: -9px;
}
.rt-tooltip {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  position: relative;
  background: #0062ff;
  border-radius: 8px;
  display: inline-block;
  padding: 12px 16px;
  box-sizing: border-box;
  color: var(--white);
  filter: drop-shadow(-4px 5px 18px rgba(29, 42, 68, 0.13));
}
.rt-tooltip__actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}
.rt-tooltip__arrow,
.rt-tooltip__arrow:before {
  position: absolute;
  width: 8px;
  height: 8px;
  z-index: -1;
}
.rt-tooltip__arrow:before {
  content: '';
  transform: rotate(45deg);
  background: #0062ff;
}
.rt-tooltip__arrow_bottom {
  bottom: -8px;
  left: calc(50% - 8px);
}
.rt-tooltip__arrow_top-right {
  top: -8px;
  right: 16px;
}
.rt-tooltip__arrow,
.rt-tooltip__arrow::before {
  width: 16px;
  height: 16px;
}
.rt-tooltip:hover .rt-tooltip__close,
.rt-tooltip__close:hover .rt-tooltip__close {
  opacity: 1;
  display: flex;
}
.rt-tooltip__close {
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -12px;
  left: 0;
  width: 20px;
  height: 20px;
  color: #92929d;
  background: var(--light-grey-3);
  border-radius: 50%;
  border: 1px solid #92929d;
  display: none;
  opacity: 0;
  transition: opacity 0.1s;
  font-size: 14px;
  line-height: 14px;
}
.rt-tooltip__close:hover {
  cursor: pointer;
  color: var(--grey-1);
  background: var(--light-grey-2);
}
.rt-tooltip__text {
  margin: 0;
}
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background-color: #0062ff;
}
.mat-checkbox .mat-checkbox-background,
.mat-checkbox .mat-checkbox-frame {
  border-radius: 4px;
}
.mat-checkbox .mat-checkbox-layout {
  white-space: normal;
}
.mat-checkbox .mat-checkbox-frame {
  border-width: 1px;
  border-color: #d5d5dc;
}
.mat-checkbox .mat-checkbox-label {
  font-family: ProximaNova, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #92929d;
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  padding-top: 2px;
}
.mat-checkbox .mat-checkbox-inner-container {
  margin-right: 12px;
}
.mat-checkbox .mat-checkbox-checkmark {
  width: 90%;
  top: 1px;
  left: 1px;
}
.mat-checkbox.mat-checkbox-disabled {
  opacity: 0.7;
}
.mat-checkbox.mat-checkbox-checked .mat-checkbox-label {
  color: var(--black);
}
.mat-checkbox.mat-checkbox_inverted .mat-checkbox-inner-container {
  order: 2;
  margin-right: 0;
  margin-left: 12px;
}
.mat-checkbox.mat-checkbox_payment .mat-checkbox-label {
  color: var(--grey-4);
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
.mat-checkbox.mat-checkbox_filter .mat-checkbox-inner-container {
  margin-right: 8px;
}
.mat-checkbox.mat-checkbox_filter.mat-checkbox-checked .mat-checkbox-label {
  color: #0062ff;
}
.mat-checkbox.mat-checkbox_filter .mat-checkbox-label,
.mat-checkbox.mat-checkbox_filter .mat-checkbox-layout {
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #696974;
}
[contenteditable='true']:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  color: var(--grey-4);
  display: block;
  /* For Firefox */
}
@keyframes checkmarkCheckOff {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  70% {
    transform: scale(0.92);
  }
  100% {
    transform: scale(1);
  }
}
.green-checkbox {
  cursor: pointer;
  position: relative;
  display: inline-block;
  user-select: none;
}
.green-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 1px;
  height: 1px;
  pointer-events: none;
}
.green-checkbox__field {
  display: flex;
  align-items: center;
}
.green-checkbox__marker {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  display: block;
}
.green-checkbox__marker:before {
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  background-color: var(--white);
  border: 1px solid #3dd598;
  display: block;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  content: '';
}
.green-checkbox__marker:after {
  width: 9px;
  height: 4px;
  border-left: 2px solid var(--white);
  border-bottom: 2px solid var(--white);
  display: block;
  transform-origin: center;
  position: absolute;
  left: 50%;
  transform: rotate(-45deg) translate(-8%, -104%) skewX(-5deg);
  opacity: 0;
  top: 50%;
  transition: none !important;
  content: '';
}
.green-checkbox__caption {
  display: block;
  position: relative;
  min-height: 16px;
  color: var(--black);
  padding-left: 8px;
  padding-top: 1px;
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
}
.green-checkbox:hover .green-checkbox__caption {
  color: var(--black);
}
.green-checkbox:hover .green-checkbox__marker:before {
  border-color: #92929d;
}
input:focus:checked + .green-checkbox__field .green-checkbox__marker {
  animation: checkmarkCheckOff 500ms cubic-bezier(0.225, 0.5, 0.165, 1);
}
input:checked + .green-checkbox__field .green-checkbox__caption {
  text-decoration: line-through;
  color: #92929d;
}
input:checked + .green-checkbox__field:hover .green-checkbox__caption {
  color: var(--black);
}
input:checked + .green-checkbox__field .green-checkbox__marker:before {
  border-color: transparent;
  width: 20px;
  height: 20px;
  background-color: rgba(61, 213, 152, 0.2);
}
input:checked + .green-checkbox__field .green-checkbox__marker:after {
  opacity: 1;
  border-color: #3dd598;
}
input:disabled + .green-checkbox__field {
  pointer-events: none;
}
input:disabled + .green-checkbox__field .green-checkbox__caption {
  color: #92929d;
}
input:disabled + .green-checkbox__field .green-checkbox__marker {
  opacity: 0.65;
}
.input {
  background-color: var(--white);
  border: 1px solid var(--light-grey-1);
  border-radius: 4px;
  padding-left: 15px;
  padding-right: 15px;
  height: 40px;
  width: 100%;
  box-sizing: border-box;
  color: var(--black);
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  line-height: normal;
}
.input::placeholder {
  color: #92929d;
}
.input_icon-right {
  padding-right: 2.5rem;
}
.input:hover {
  border-color: var(--grey-4);
}
.input.input_error {
  border-color: #fc5a5a;
  background-color: rgba(252, 90, 90, 0.05);
}
.input.input_ok {
  border-color: #3dd598;
}
.input:focus,
.input_active {
  border-color: #50b5ff;
}
.input:disabled {
  pointer-events: none;
  background-color: var(--light-grey-3);
  border-color: var(--light-grey-1);
  color: #92929d;
}
.input.input_datepicker {
  padding-left: 3rem;
  position: relative;
  display: flex;
  align-items: center;
}
.input.input_datepicker .ui-icon {
  position: absolute;
  left: 13px;
  top: 50%;
  transform: translateY(-50%);
}
input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  display: none;
}
.radio {
  position: relative;
  display: inline-block;
  user-select: none;
}
.radio input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 1px;
  height: 1px;
  pointer-events: none;
}
.radio input + span {
  display: block;
  padding-left: 28px;
  min-height: 16px;
  color: #92929d;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
}
.radio input + span:before {
  width: 16px;
  height: 16px;
  background-color: var(--white);
  border: 1px solid #92929d;
  display: block;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: '';
}
.radio input + span:hover {
  color: #696974;
}
.radio input + span:hover:before {
  border-color: #0062ff;
}
.radio input:focus + span {
  color: #696974;
}
.radio input:focus + span:before {
  border-color: #0062ff;
}
.radio input:focus + span:hover {
  color: #696974;
}
.radio input:focus + span:hover:before {
  border-color: #0062ff;
}
.radio input:checked + span:before {
  box-shadow: inset 0px 0px 0px 4px #0062ff;
  border-color: #0062ff;
}
.radio input:disabled + span {
  pointer-events: none;
  color: #92929d;
  cursor: default;
}
.radio input:disabled + span:before {
  background-color: var(--light-grey-2);
  border-color: #d5d5dc;
}
.radio input:checked:disabled + span:before {
  border-color: rgba(0, 98, 255, 0.5);
  box-shadow: inset 0px 0px 0px 4px rgba(0, 98, 255, 0.5);
}
.textarea {
  background-color: var(--white);
  border: 1px solid var(--light-grey-1);
  border-radius: 4px;
  padding: 12px 15px;
  height: 148px;
  width: 100%;
  box-sizing: border-box;
  color: var(--black);
  resize: none;
  font-family: 'ProximaNova', sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
}
.textarea::placeholder {
  color: #92929d;
}
.textarea:hover {
  border-color: var(--grey-4);
}
.textarea.textarea_error {
  border-color: #fc5a5a;
  background-color: rgba(252, 90, 90, 0.05);
}
.textarea.textarea_ok {
  border-color: #3dd598;
}
.textarea:focus {
  border-color: #50b5ff;
}
.textarea:disabled {
  pointer-events: none;
  background-color: var(--light-grey-3);
  border-color: var(--light-grey-1);
  color: #92929d;
}
.label {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #92929d;
  display: block;
  margin-bottom: 8px;
}
.field {
  position: relative;
  width: 100%;
}
.field .field__icon-right {
  position: absolute;
  right: 13px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: fit-content;
}
.form-group {
  margin-bottom: 16px;
}
.form-group_big {
  margin-bottom: 24px;
}
.error-text {
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  color: #fc5a5a;
}
.label-group {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-end;
}
.label-group > :first-child {
  margin-right: 5px;
}
.label-group__error {
  margin-bottom: 7px;
  line-height: 16px;
  display: flex;
  flex-direction: column;
}
.input-with-btn {
  position: relative;
  display: flex;
  background-color: var(--light-grey-3);
  border-radius: 8px;
}
.input-with-btn__input {
  background-color: transparent;
  border: 0;
  height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  flex-grow: 1;
  color: var(--black);
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  border-radius: 8px 0 0 8px;
}
.input-with-btn__input::placeholder {
  color: #92929d;
}
.input-with-btn__input:disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.75;
}
.input-with-btn__btn {
  border: 0;
  margin: 0;
  background-color: transparent;
  flex-shrink: 0;
  border-radius: 0 8px 8px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  color: #92929d;
  -webkit-appearance: none;
  padding: 0 25px;
  height: 40px;
}
.input-with-btn__btn:hover,
.input-with-btn__btn:focus {
  color: var(--black);
  transition: color 0.25s ease;
}
.input-with-btn__btn:hover .ui-icon,
.input-with-btn__btn:focus .ui-icon {
  color: var(--black);
}
.input-with-btn__btn:disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.75;
}
.input-with-btn:focus-within {
  background-color: rgba(80, 181, 255, 0.05);
}
.input-with-btn .input-with-btn__btn-icon {
  margin-left: 8px;
}
.owl-dt-calendar-table .owl-dt-calendar-cell {
  color: var(--black);
}
.owl-dt-control-content {
  color: var(--black);
}
.owl-dt-calendar-control {
  padding: 1em 0.5em 1em 0.5em;
}
.owl-dt-control-arrow-button {
  width: 32px;
  height: 32px;
  background: var(--light-grey-3);
  border-radius: 4px;
}
.owl-dt-control-arrow-button .owl-dt-control-button-content {
  color: var(--grey-1);
}
.owl-dt-control-arrow-button:disabled .owl-dt-control-button-content {
  color: var(--grey-4);
}
.owl-dt-calendar-table .owl-dt-calendar-cell-disabled > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
  color: var(--grey-4);
}
.owl-dt-calendar-table .owl-dt-calendar-cell-out {
  opacity: 1;
  color: var(--grey-4);
}
.owl-dt-popup-container {
  border-radius: 8px;
  box-shadow: -4px 5px 18px rgba(29, 42, 68, 0.13);
}
.owl-dt-calendar-table-divider {
  display: none;
}
.owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-weekdays th {
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  color: #92929d;
}
.owl-dt-calendar-table .owl-dt-calendar-header {
  color: var(--grey-4);
}
.owl-dt-calendar-table .owl-dt-calendar-cell-selected.owl-dt-calendar-cell-today {
  box-shadow: none;
}
.owl-dt-calendar-table .owl-dt-calendar-cell-active:focus > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected),
.owl-dt-calendar-table :not(.owl-dt-calendar-cell-disabled):hover > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
  background: transparent;
}
.owl-dt-calendar-table .owl-dt-calendar-cell-active:focus:not(:hover) > .owl-dt-calendar-cell-content.owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected),
.owl-dt-calendar-cell-content .owl-dt-calendar-cell-today,
.owl-dt-calendar-table .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
  border: none;
  background: #fc5a5a;
  color: var(--white);
}
.owl-dt-calendar-table :not(.owl-dt-calendar-cell-disabled):hover > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
  border: 1px solid #0062ff;
  background: rgba(0, 98, 255, 0.05);
  border-radius: 4px;
  color: var(--black);
}
.owl-dt-control-period-button:hover > .owl-dt-control-button-content {
  background-color: var(--light-grey-2);
}
.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
  background-color: #0062ff;
  border-radius: 4px;
  color: var(--white);
  border: none;
}
.owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-weekdays th {
  padding-bottom: 0;
  text-transform: uppercase;
}
.owl-dt-inline-container .owl-dt-calendar,
.owl-dt-popup-container .owl-dt-calendar {
  height: auto;
}
*:focus {
  outline: none;
}
[hidden] {
  display: none !important;
}
html,
body {
  min-height: 100%;
  height: 100%;
  width: 100%;
}
body {
  color: var(--black);
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
body_style_1 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
body_style_2 {
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
}
body_style_3 {
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
}
body {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  padding: 0;
  margin: 0;
  font-family: ProximaNova, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: var(--light-grey-3);
  transition: background-color 50ms ease-out;
  -webkit-tap-highlight-color: transparent;
}
body.rt-body-white {
  background: var(--white);
}
body.no-mac ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
body.no-mac ::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  background: transparent;
}
body.no-mac ::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0 none #ffffff;
  border-radius: 50px;
}
body.no-mac ::-webkit-scrollbar-thumb:hover {
  background: #b6b6b6;
}
body.no-mac ::-webkit-scrollbar-thumb:active {
  background: #b6b6b6;
}
body.no-mac ::-webkit-scrollbar-track {
  background: transparent;
  border: 0 none transparent;
  border-radius: 14px;
}
body.no-mac ::-webkit-scrollbar-track:hover {
  background: transparent;
}
body.no-mac ::-webkit-scrollbar-track:active {
  background: transparent;
}
body.no-mac ::-webkit-scrollbar-corner {
  background: transparent;
}
button,
input {
  font-family: ProximaNova, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: inherit;
  font-weight: inherit;
}
.overflow {
  overflow: hidden;
}
a {
  cursor: pointer;
  text-decoration: none;
}
.logtime__hashtag,
.logtime__linky-link {
  word-wrap: break-word;
  color: #409ded;
}
.logtime__hashtag:hover,
.logtime__linky-link:hover,
.logtime__hashtag:focus,
.logtime__linky-link:focus {
  text-decoration: underline;
}
/* Animate items as they're being sorted. */
.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
/* Animate an item that has been dropped. */
.cdk-drag-animating {
  transition: transform 300ms;
}
.cdk-drag-animating.cdk-drag-animating-hide {
  opacity: 0;
}
/* Скрытие svg спрайта */
body > svg {
  display: none;
}
#chatra:not(.chatra--expanded) {
  visibility: hidden !important;
  opacity: 0 !important;
  pointer-events: none;
  transition: none;
}
