.label-group {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-end;
  & > :first-child {
    margin-right: 5px;
  }
  &__error {
    margin-bottom: 7px;
    line-height: 16px;
    display: flex;
    flex-direction: column;
  }
}
