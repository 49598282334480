@keyframes checkmarkCheckOff {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.2);
  }

  70% {
    transform: scale(0.92);
  }

  100% {
    transform: scale(1);
  }
}

.green-checkbox {
  cursor: pointer;
  position: relative;
  display: inline-block;
  user-select: none;

  input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 1px;
    height: 1px;
    pointer-events: none;
  }

  &__field {
    display: flex;
    align-items: center;
  }

  &__marker {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    position: relative;
    display: block;
    &:before {
      box-sizing: border-box;
      width: 16px;
      height: 16px;
      background-color: @white;
      border: 1px solid @green;
      display: block;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      content: '';
    }
    &:after {
      width: 9px;
      height: 4px;
      border-left: 2px solid @white;
      border-bottom: 2px solid @white;
      display: block;
      transform-origin: center;
      position: absolute;
      left: 50%;
      transform: rotate(-45deg) translate(-8%, -104%) skewX(-5deg);
      opacity: 0;
      top: 50%;
      transition: none !important;
      content: '';
    }
  }

  &__caption {
    display: block;
    position: relative;
    min-height: 16px;
    color: @black;
    padding-left: 8px;
    padding-top: 1px;
    .body-text_style_2();
  }

  &:hover {
    .green-checkbox__caption {
      color: @black;
    }
    .green-checkbox__marker {
      &:before {
        border-color: @grey-3;
      }
    }
  }

  input:focus:checked + &__field &__marker {
    animation: checkmarkCheckOff 500ms cubic-bezier(0.225, 0.5, 0.165, 1);
  }

  input:checked + &__field {
    .green-checkbox__caption {
      text-decoration: line-through;
      color: @grey-3;
    }
    &:hover {
      .green-checkbox__caption {
        color: @black;
      }
    }
  }

  input:checked + &__field &__marker {
    &:before {
      border-color: transparent;
      width: 20px;
      height: 20px;
      background-color: fade(@green, 20%);
    }

    &:after {
      opacity: 1;
      border-color: @green;
    }
  }

  input:disabled + &__field {
    pointer-events: none;
    .green-checkbox__caption {
      color: @grey-3;
    }
    .green-checkbox__marker {
      opacity: 0.65;
    }
  }
}
