// стили, которые по каким-то причинам должны быть глобальные

/* Drag and Drop */

.note-form__subtask-text {
  flex-basis: 100%;
  box-sizing: border-box;
  margin: 0 0 0 -6px;
  min-height: 32px;
  padding: 7px 0 0 6px;
  .body-text_style_2();
  color: @black;
  font-family: @font;
  background-color: transparent;
  border: none;
  border-radius: @border-radius-form;
  resize: none;
  .transition-bg-color();

  &:hover,
  &:focus {
    background-color: rgba(0, 98, 255, 0.05);
  }
}

.note-form__subtask {
  &.cdk-drag-preview {
    .note-form__subtask-delete {
      display: none;
    }
  }
}

.bold {
  font-weight: bold;
  color: @black;
}

.custom-preview() {
  height: auto !important;
  box-sizing: border-box;
  border-radius: @border-radius-default;
  box-shadow: @big-shadow;
  background: @white;
  transition: 0.2s;
  width: 98%;
}

.custom-preview {
  .custom-preview();
}

.custom-preview-opacity {
  .custom-preview();
  opacity: 0.5;
}

.custom-preview-animating {
  opacity: 0;
}

.cdk-drag-animating {
  //opacity: 0;
}

/* End drag and drop */

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.reminder-container {
  .popup-prop();
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.reminder-menu-item {
  .button-reset();
  .body-text_style_1();
  .transition-bg-color();
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 8px;
  border-radius: 8px;
  color: @grey-2;

  &:hover {
    background: @light-grey-3;
  }

  &_active {
    color: @blue-primary;
    .reminder-menu-item__arrow {
      color: @blue-primary;
    }

    .reminder-menu-item__date {
      color: @blue-primary;
    }

    .reminder-menu-item__icon-done {
      opacity: 1;
    }
  }

  &__arrow {
    color: @grey-4;
  }

  &__light {
    opacity: 0.45;
  }

  &__icon-done {
    opacity: 0;
    color: @blue-primary;
  }

  &__group {
    display: flex;
    align-items: center;

    &_main {
      color: @black;
    }
  }

  &__main-icon {
    margin-right: 12px;
  }

  &__name {
  }

  &__icon-delete {
    color: @grey-3;
    margin-right: 4px;
  }

  &__date {
    margin-right: 8px;
    color: @grey-3;
  }

  &__delete {
    color: @grey-3;
  }
}

.reminder-table-item {
  user-select: none;
  box-sizing: border-box;

  input + label {
    .body-text_style_1();
    .transition-all();
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    padding: 6px;
    border-radius: 4px;
    color: @grey-1;
    box-sizing: border-box;

    &:hover {
      background: @light-grey-2;
    }

    &:active {
      background: @light-grey-3;
    }
  }

  input:checked + label {
    background: @blue-primary;
    color: @white;
  }

  &_last {
    input + label {
      white-space: nowrap;
      width: fit-content;
      justify-content: flex-start;
    }
  }
}
