.field {
  position: relative;
  width: 100%;
  & &__icon-right {
    position: absolute;
    right: 13px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: fit-content;
  }
}
