.textarea {
  background-color: @white;
  border: 1px solid @light-grey-1;
  border-radius: @border-radius-form;
  padding: 12px 15px;
  height: 148px;
  width: 100%;
  box-sizing: border-box;
  color: @black;
  resize: none;
  font-family: 'ProximaNova', sans-serif;
  .body-text_style_2();

  &::placeholder {
    color: @grey-3;
  }
  &:hover {
    border-color: @grey-4;
  }
  &&_error {
    border-color: @red;
    background-color: fadeout(@red, 95%);
  }
  &&_ok {
    border-color: @green;
  }
  &:focus {
    border-color: @blue;
  }
  &:disabled {
    pointer-events: none;
    background-color: @light-grey-3;
    border-color: @light-grey-1;
    color: @grey-3;
  }
}
