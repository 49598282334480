@import '~src/less/typography.less';
@import '~src/less/mixins.less';

.onboarding {
  box-sizing: border-box;
  position: absolute;
  visibility: visible;
  padding: 20px;
  background-color: @Color-blue;
  color: @white;
  width: 302px;
  border-radius: 3px;
  .shadow-small();
  -webkit-transition: opacity 0.1s ease-out;
  -moz-transition: opacity 0.1s ease-out;
  -ms-transition: opacity 0.1s ease-out;
  -o-transition: opacity 0.1s ease-out;
  transition: opacity 0.1s ease-out;
  &__list {
    padding-left: 15px;
    margin-bottom: 18px;
  }
  &_left {
    right: 100%;
    top: -25px;
    margin-right: 50px;
  }
  &__btn {
    margin-top: 16px;
    text-align: right;
    padding: 11px 0;
  }
  &__title {
    margin: 0 0 16px;
  }
  &__text {
    margin: 0 0 16px;
    display: block;
    &_with-devider {
      padding-left: 20px;
    }
  }
  &__item {
    padding-left: 3px;
    margin-bottom: 0;
    position: relative;
    z-index: 1;
    &_numb {
      display: list-item;
    }
    &_dev {
      &:before {
        content: '\2014';
        position: absolute;
        left: -17px;
        top: 0;
      }
    }
  }
  &__devider {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }
  &__arrow {
    border: 15px solid transparent;
    content: '';
    position: absolute;
    &_top {
      top: -30px;
      border-bottom-color: @Color-blue;
    }
    &_top-right {
      top: -30px;
      right: -30px;
      border-bottom-color: @Color-blue;
    }
    &_top-middle {
      top: -30px;
      left: 50%;
      margin-left: -5px;
      border-bottom-color: @Color-blue;
    }
    &_right {
      right: -30px;
      top: 25px;
      border-left-color: @Color-blue;
    }
    &_right-bottom {
      bottom: 10px;
      right: -30px;
      border-left-color: @Color-blue;
    }
    &_bottom {
      bottom: -30px;
      border-top-color: @Color-blue;
    }
    &_bottom-right {
      bottom: -10px;
      right: 10px;
      border-top-color: @Color-blue;
    }
    &_bottom-middle {
      bottom: -10px;
      left: 50%;
      margin-left: -5px;
      border-top-color: @Color-blue;
    }
    &_left,
    &_left-bottom {
      left: -30px;
      border-right-color: @Color-blue;
    }
    &-left {
      top: 40px;
    }
    &_left-bottom {
      bottom: 23px;
    }
  }
  &__img {
    display: grid;
    justify-content: center;
    margin-bottom: 16px;
  }
  &__icon {
    &-rocket {
      width: 124px;
      height: 124px;
      color: @white;
    }
  }
  .btn {
    margin-left: 16px;
  }
}

.new-onboarding-dialog {
  position: relative;
  display: block;
  box-sizing: border-box;
  width: 407px;
  padding: 48px;
  border-radius: 8px;
  text-align: center;
  background: @white;
  color: @grey-1;
  @media @Media-max-650 {
    width: 100vw;
    padding: 32px;
  }
  &__button {
    display: inline-block;
    box-sizing: border-box;
    width: 344px;
    max-width: 100%;
    padding: 12px;
    border-radius: 8px;
    text-align: center;
    color: @white;
  }
  &__close {
    position: absolute !important;
    z-index: 1;
    top: 24px;
    right: 24px;
  }
  &__img {
    margin-right: 16px;
  }
  &__item {
    margin-bottom: 16px;
    padding-left: 3px;
    &_numberless {
      list-style: none;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    margin: 0 0 24px;
  }
  &__green {
    color: @green;
  }
  &__list {
    box-sizing: border-box;
    margin-bottom: 32px;
    // max-width: 292px;
    padding-left: 18px;
    text-align: left;
  }
  &__step-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: rgba(@green, 0.3);
    font-size: 28px;
    line-height: 36px;
    color: @green;
    font-weight: bold;
  }
  &__title {
    .heading();
    .heading_h3();
    margin: 0;
    color: @black;
  }
}
