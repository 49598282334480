@import '~src/less/variables.less';
@import '~src/less/mixins.less';

@arrow-offset: 4px;
@big-arrow-offset: 9px;

.tooltip {
  &__arrow {
    &,
    &:before {
      position: absolute;
      width: 8px;
      height: 8px;
      z-index: -1;
    }
    &:before {
      content: '';
      transform: rotate(45deg);
      background: @blue-primary;
    }
    &_big {
      &,
      &:before {
        width: 24px;
        height: 24px;
      }
    }
    &_hidden {
      display: none;
    }
  }
  &_no-space {
    display: flex;
  }
  &__tip {
    background: @blue-primary;
    color: @white;
    border-radius: @border-radius-default;
    box-shadow: unset;
    padding: 9px 16px 8px;
    text-align: unset;
    width: unset;
    animation-name: showUp;
    animation-duration: 0.4s;
  }
  &__tip[data-popper-placement^='top'] > &__arrow {
    bottom: -@arrow-offset;
    &_big {
      bottom: -@big-arrow-offset;
    }
  }

  &__tip[data-popper-placement^='bottom'] > &__arrow {
    top: -@arrow-offset;
    &_big {
      top: -@big-arrow-offset;
    }
  }

  &__tip[data-popper-placement^='left'] > &__arrow {
    right: -@arrow-offset;
    &_big {
      right: -@big-arrow-offset;
    }
  }

  &__tip[data-popper-placement^='right'] > &__arrow {
    left: -@arrow-offset;
    &_big {
      left: -@big-arrow-offset;
    }
  }
}

.rt-tooltip {
  .body-text_style_1();
  position: relative;
  background: @blue-primary;
  border-radius: @border-radius-default;
  display: inline-block;
  padding: 12px 16px;
  box-sizing: border-box;
  color: @white;
  filter: drop-shadow(-4px 5px 18px rgba(29, 42, 68, 0.13));
  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
  }
  &__arrow {
    &,
    &:before {
      position: absolute;
      width: 8px;
      height: 8px;
      z-index: -1;
    }
    &:before {
      content: '';
      transform: rotate(45deg);
      background: @blue-primary;
    }
    &_bottom {
      bottom: -8px;
      left: calc(50% - 8px);
    }
    &_top-right {
      top: -8px;
      right: 16px;
    }
    &,
    &::before {
      width: 16px;
      height: 16px;
    }
  }
  &:hover,
  &__close:hover {
    .rt-tooltip__close {
      opacity: 1;
      display: flex;
    }
  }
  &__close {
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -12px;
    left: 0;
    width: 20px;
    height: 20px;
    color: @grey-3;
    background: @light-grey-3;
    border-radius: 50%;
    border: 1px solid @grey-3;
    display: none;
    opacity: 0;
    transition: opacity 0.1s;
    font-size: 14px;
    line-height: 14px;
    &:hover {
      cursor: pointer;
      color: @grey-1;
      background: @light-grey-2;
    }
  }
  &__text {
    margin: 0;
  }
}
