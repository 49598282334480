.circle {
  width: 8px;
  height: 8px;
  flex-shrink: 0;
  display: inline-block;
  border-radius: @Radius-full;
  &_select {
    margin-right: 12px;
  }
}
