.matdialog {
  &_adaptive {
    @media (max-width: 650px) {
      width: 100vw;
      border-radius: 8px 8px 0 0;
    }
  }
  &__panel {
    &_global-scroll {
      position: fixed !important;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100vw;
      height: 100vh;
      overflow: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      .mat-dialog-container {
        width: 100%;
        max-width: 100%;
        height: 100vh;
        overflow: auto;
        box-shadow: none;
        display: flex;
        & > * {
          display: block;
          margin: auto;
          background: @white;
          border-radius: 8px;
        }
      }
    }
  }
}

.black-backdrop {
  background: rgba(0, 0, 0, 0.7);
}
