.radio {
  position: relative;
  display: inline-block;
  user-select: none;
  input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 1px;
    height: 1px;
    pointer-events: none;
  }

  input + span {
    display: block;
    padding-left: 28px;
    min-height: 16px;
    color: @grey-3;
    cursor: pointer;
    .body-text_style_2();
    &:before {
      width: 16px;
      height: 16px;
      background-color: @white;
      border: 1px solid @grey-3;
      display: block;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      content: '';
    }
    &:hover {
      color: @grey-2;
      &:before {
        border-color: @blue-primary;
      }
    }
  }

  input:focus + span {
    color: @grey-2;
    &:before {
      border-color: @blue-primary;
    }

    &:hover {
      color: @grey-2;
      &:before {
        border-color: @blue-primary;
      }
    }
  }

  input:checked + span {
    &:before {
      box-shadow: inset 0px 0px 0px 4px @blue-primary;
      border-color: @blue-primary;
    }
  }

  input:disabled + span {
    pointer-events: none;
    color: @grey-3;
    cursor: default;
    &:before {
      background-color: @light-grey-2;
      border-color: @grey-5;
    }
  }

  input:checked:disabled + span {
    &:before {
      border-color: fadeout(@blue-primary, 50%);
      box-shadow: inset 0px 0px 0px 4px fadeout(@blue-primary, 50%);
    }
  }
}
