@import '~src/less/variables';
@import '~src/less/typography';

// Ui Material Design Reinit styles

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element {
  background-color: @blue-primary;
}

.mat-checkbox {
  .mat-checkbox-background,
  .mat-checkbox-frame {
    border-radius: @border-radius-form;
  }

  .mat-checkbox-layout {
    white-space: normal;
  }

  .mat-checkbox-frame {
    border-width: 1px;
    border-color: @grey-5;
  }

  .mat-checkbox-label {
    font-family: @font;
    color: @grey-3;
    .body-text_style_2();
    padding-top: 2px;
  }

  .mat-checkbox-inner-container {
    margin-right: 12px;
  }

  .mat-checkbox-checkmark {
    width: 90%;
    top: 1px;
    left: 1px;
  }

  &.mat-checkbox-disabled {
    opacity: 0.7;
  }

  &.mat-checkbox-checked {
    .mat-checkbox-label {
      color: @black;
    }
  }

  &.mat-checkbox_inverted {
    .mat-checkbox-inner-container {
      order: 2;
      margin-right: 0;
      margin-left: 12px;
    }
  }
  &.mat-checkbox_payment {
    .mat-checkbox-label {
      color: @grey-4;
      .body-text_style_1();
    }
  }
  &.mat-checkbox_filter {
    .mat-checkbox-inner-container {
      margin-right: 8px;
    }
    &.mat-checkbox-checked {
      .mat-checkbox-label {
        color: @blue-primary;
      }
    }
    .mat-checkbox-label,
    .mat-checkbox-layout {
      width: 100%;
      .body-text_style_1();
      color: @grey-2;
    }
  }
}
