.input-with-btn {
  position: relative;
  display: flex;
  background-color: @light-grey-3;
  border-radius: @border-radius-default;
  &__input {
    background-color: transparent;
    border: 0;
    height: 40px;
    padding-left: 16px;
    padding-right: 16px;
    flex-grow: 1;
    color: @black;
    .body-text_style_2();
    border-radius: @border-radius-default 0 0 @border-radius-default;
    &::placeholder {
      color: @grey-3;
    }
    &:disabled {
      pointer-events: none;
      user-select: none;
      opacity: 0.75;
    }
  }
  &__btn {
    border: 0;
    margin: 0;
    background-color: transparent;
    flex-shrink: 0;
    border-radius: 0 @border-radius-default @border-radius-default 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    .body-text_style_2();
    color: @grey-3;
    -webkit-appearance: none;
    padding: 0 25px;
    height: 40px;

    &:hover,
    &:focus {
      color: @black;
      transition: color 0.25s ease;
      .ui-icon {
        color: @black;
      }
    }

    &:disabled {
      pointer-events: none;
      user-select: none;
      opacity: 0.75;
    }
  }
  &:focus-within {
    background-color: fadeout(@blue, 95%);
  }
  .input-with-btn__btn-icon {
    margin-left: 8px;
  }
}
